import * as React from "react";
import { graphql, HeadFC } from "gatsby";
import { ContentsList } from "./main";
import Button from "../../components/elements/Button/Button";
import DivisionAbstruct from "../../components/elements/DivisionAbstruct/DivisionAbstruct";
import Hr from "../../components/elements/Hr/Hr";
import LeftBar from "../../components/elements/LeftBar/LeftBar";
import PageHeader from "../../components/elements/PageHeader/PageHeader";
import PageImage from "../../components/elements/PageImage/PageImage";
import PageInnerHeader from "../../components/elements/PageInnerHeader/PageInnerHeader";
import Layout from "../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../components/layouts/ResponsiveBlock/ResponsiveBlock";
import { shuffle } from "../../utils/shuffle/shuffle";
import SnsShare from "../../components/elements/SnsShare/SnsShare";
import DisclosureElement from "../../components/elements/DisclosureElement/DisclosureElement";
import Disclosure from "../../components/elements/Disclosure/Disclosure";
import { Link } from "gatsby";
import { CommonHead } from "../../components/commons/Head/Head";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
    allMdx(
      filter: {
        frontmatter: {
          isMain: { eq: true }
          category: { eq: "flight" }
          publish: { eq: true }
        }
      }
      sort: { order: ASC, fields: frontmatter___priority }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          thumbnail
        }
      }
    }
  }
`;

const ContentsHeader = ({ imageSrc, title, url }: any) => {
  return (
    <div className="tw-relative">
      <Link to={url}>
        <img src={imageSrc} alt={title} className="tw-w-full" />
        <p className="tw-absolute tw-left-0 tw-bottom-0 tw-py-2 tw-pl-6 tw-pr-16 tw-rounded-tr tw-bg-baseWhite tw-text-xl tw-font-bold md:tw-py-6 md:tw-pl-8 md:tw-pr-52 md:tw-text-3xl">
          {title}
        </p>
        <div className="tw-absolute tw-right-6 tw-bottom-2 md:tw-right-12 md:tw-bottom-6">
          <Button url={url} name="MORE" />
        </div>
      </Link>
    </div>
  );
};

const IndexPage = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  const nodes = data.allMdx?.nodes ?? [];
  return (
    <Layout title={title} pageType="TopPage">
      <div className="tw-c-page-top md:tw-c-page-top-md">
        <PageHeader
          title="あなたの知らないエアライン業界"
          leadSentence="エアライン業界に3000名以上のプロを生んだ学科が、キャビンアテンダントとグランドスタッフのリアルな姿を伝えます。"
        />
        <PageImage
          imageSrc="/images/flight/AL-TOP1-1.jpg"
          sentence1="飛行機の中や空港で"
          sentence2="起きているリアル"
        />
        <LeftBar>
          <ResponsiveBlock bgColor="">
            <PageInnerHeader
              title="エアライン業界が面白い"
              leadSentence="空のプロにあこがれる人たちが、本当にプロになる学科です。プロの仕事は学校で学んでいたときの想像以上に興奮することも、信じられないシーンに巡り合うこともあります。"
            />
            <ContentsHeader
              imageSrc="/images/flight/AL-TOP2.jpg"
              title="航空会社のリアル"
              url="/flight/main/"
            />
            <div className="md:tw-mt-4 md:tw-mb-10">
              <ContentsList nodes={shuffle(nodes).slice(0, 2)} />
            </div>
          </ResponsiveBlock>
        </LeftBar>
        <DisclosureElement
          title="国際エアライン科で学ぶこと"
          imageSrc="/images/flight/AL-TOP3.jpg"
        >
          <Disclosure num="01" title="お客様の安全と安心">
            エアライン業界で働くうえで最も大切なことは、お客様の安全と安心を守ることです。キャビンアテンダントはさまざまな保安業務の知識を持ち、緊急事態でも冷静な判断や行動ができることが基本です。グランドスタッフも多くのお客様の安全に備えて注意を怠りませんし、運航スケジュールに急な変更が生じても、お客様に安心して飛行機を利用していただけるように的確な対処をします。
          </Disclosure>
          <Disclosure num="02" title="プロの手際と気遣い">
            飛行機の中でも空港でも、全ての仕事が予定通りに進むことはほとんどありません。一人ひとりのお客様の急なご要望やトラブルへの対応も大切ですし、そもそも飛行機を利用する目的によってもキャビンアテンダントやグランドスタッフが配慮すべきポイントはお客様ごとに違います。そのうえ、一人のお客様対応に割ける時間は限られていますから、瞬時の判断で最高のおもてなしをするのがプロのワザです。
          </Disclosure>
          <Disclosure num="03" title="自分を美しく見せる">
            キャビンアテンダントもグランドスタッフも、ホスピタリティのプロとしていつもお客様から注目されています。身だしなみはもちろん歩き方や話し方まで、全ての立ち居振る舞いがプロとしてのサービスです。外見だけでなく仕草や声まで自分を美しく魅力的に感じていただくために、どのような準備や訓練が必要になるかをしっかり学んでください。
          </Disclosure>
        </DisclosureElement>
        <DivisionAbstruct />
        <Hr />
        <SnsShare path="/flight" title={title} spaceBottom />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={`国際エアライン科 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    ogDescription="エアライン業界に3000名以上のプロを生んだ学科が、キャビンアテンダントとグランドスタッフのリアルな姿を伝えます。"
    ogImageFilepath="/images/flight/AL-TOP1-1.jpg"
  >
    <title>国際エアライン科 - {data.allSettingsJson?.nodes[0].siteTitle}</title>
  </CommonHead>
);
