import * as React from "react";
import { graphql, HeadFC, Link } from "gatsby";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import Button from "../../../components/elements/Button/Button";
import DivisionAbstruct from "../../../components/elements/DivisionAbstruct/DivisionAbstruct";
import Hr from "../../../components/elements/Hr/Hr";
import LeftBar from "../../../components/elements/LeftBar/LeftBar";
import PageHeader from "../../../components/elements/PageHeader/PageHeader";
import Layout from "../../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../../components/layouts/ResponsiveBlock/ResponsiveBlock";
import { shuffle } from "../../../utils/shuffle/shuffle";
import { CommonHead } from "../../../components/commons/Head/Head";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
    allMdx(
      filter: {
        frontmatter: {
          isMain: { eq: true }
          category: { eq: "flight" }
          publish: { eq: true }
        }
      }
      sort: { order: ASC, fields: frontmatter___priority }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          thumbnail
        }
      }
    }
  }
`;

export const ContentsList = ({ nodes }: any) => {
  return (
    nodes.length > 0 && (
      <ul className="md:tw-flex md:tw-flex-wrap">
        {nodes.map((node: any, index: any) => (
          <li
            key={index}
            className="tw-flex tw-px-4 tw-py-6 tw-border-b tw-border-baseLightGray md:tw-w-1/2 md:tw-py-10"
          >
            <div className="tw-w-1/2 tw-mr-6 tw-mb-6">
              <Link to={node?.fields?.slug}>
                <img
                  src={
                    node?.frontmatter?.thumbnail ??
                    "/images/common/now_printing.png"
                  }
                  alt=""
                  className="tw-w-full tw-rounded-xl"
                  style={{
                    boxShadow:
                      "0px 0px 16px -6px #BAE6FA, 24px 24px 0px 0px #BAE6FA",
                  }}
                />
              </Link>
            </div>
            <div className="tw-relative tw-w-1/2 tw-px-6 tw-py-4">
              <Link to={node?.fields?.slug}>
                <p className="tw-underline md:tw-text-2xl">
                  {node?.frontmatter?.title}
                </p>
                <ArrowSmallRightIcon className="tw-absolute tw-right-0 tw-bottom-0 tw-w-8 tw-h-8" />
              </Link>
            </div>
          </li>
        ))}
      </ul>
    )
  );
};

const Header2 = ({ title }: any) => {
  return (
    <ResponsiveBlock bgColor="">
      <h2 className="tw-px-6 tw-py-8 tw-border-b tw-border-baseLightGray tw-text-2xl tw-font-bold md:tw-px-0 md:tw-text-3xl">
        {title}
      </h2>
    </ResponsiveBlock>
  );
};

const IndexPage = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  const nodes = data.allMdx?.nodes;
  return (
    <Layout title={title} pageType="TopPage">
      <>
        <PageHeader title="エアライン業界が面白い" />
        <Header2 title="航空会社のリアル" />
        <LeftBar>
          <ResponsiveBlock bgColor="">
            <ContentsList nodes={nodes} />
          </ResponsiveBlock>
        </LeftBar>
        <div className="tw-py-8 tw-text-center md:tw-py-16">
          <Button url="/flight/" name="戻る" />
        </div>
        <Hr />
        <DivisionAbstruct spaceBottom={true} />
      </>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={`エアライン業界が面白い - 国際エアライン科 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    ogDescription="エアライン業界に3000名以上のプロを生んだ学科が、キャビンアテンダントとグランドスタッフのリアルな姿を伝えます。"
    ogImageFilepath="/images/flight/AL-TOP1-1.jpg"
  >
    <title>
      エアライン業界が面白い - 国際エアライン科 -{" "}
      {data.allSettingsJson?.nodes[0].siteTitle}
    </title>
  </CommonHead>
);
